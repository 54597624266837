import { ReactElement, PropsWithChildren } from 'react';
import {
  ReservationsIcon,
  CalendarIcon,
  PersonIcon,
  AssignCaptainIcon,
  PlanGenerationIcon,
  PeopleIcon,
  DollarIcon,
  BriefcaseIcon,
  BusIcon,
  CustomersIcon,
  PlanningIcon,
  DemandIcon,
  PrivateBusIcon,
  InvoiceIcon,
  BulkActionsIcon,
  SettingsSuggestIcon,
  SupplyIcon,
  FlashIcon,
} from '@swvl/icons';
import * as paths from '@shared/paths';
import env from '@utils/env';
import { CustomersLayout } from '@customers/layout';
import { EmployeesLayout } from '@customers/employees/layout';
import { CorporateLayout } from '@customers/corporates/layout';
import { PrivateRideLayout } from '@customers/private-ride/layout';
import { ReservationsLayout } from '@customers/reservations/layout';
import { PlanningLayout } from '@planning/layout';
import { DemandLayout } from '@demand/layout';
import { PrivateBusLayout } from '@private-bus/layout';
import { SupplyLayout } from '@supply/layout';
import { AccessControlLayout } from '@access-control/layout';
import { BulkActionsLayout } from '@bulk-actions/layout';
import { InvoiceLayout } from '@invoice/layout';
import { CanPermission } from '@swvl/gandalf-can';
import { saasModulesPermissions } from '@shared/constants';
import { FleetLayout } from '@fleet/layout';

type SubModule = {
  key: string;
  path: string;
  title: string;
  icon?: JSX.Element;
  layout?: ({ children }: PropsWithChildren<unknown>) => ReactElement;
  permission?: CanPermission;
};

type Modules = {
  [key: string]: {
    path: string;
    title: string;
    icon?: JSX.Element;
    subModules: SubModule[];
    layout?: ({ children }: PropsWithChildren<unknown>) => ReactElement;
    hideFromModulesListing?: boolean;
  };
};

const isPlanningEnabled = env('IS_PLANNING_ENABLED');
const isSupplyEnabled = env('IS_SUPPLY_ENABLED');
const isDemandEnabled = env('IS_DEMAND_ENABLED');
const isBulkActionsEnabled = env('IS_BULK_ACTIONS_ENABLED');
const isPlanGenerationEnabled = env('IS_PLAN_GENERATION_ENABLED');
const isSupplyPlansEnabled = env('IS_SUPPLY_PLANS_ENABLED');
const isPrivateBusEnabled = env('IS_PRIVATE_BUS_ENABLED');
const isInvoiceEnabled = env('IS_Invoice_ENABLED');

const routingModules: Modules = {
  customers: {
    path: '/customers',
    title: 'Customers',
    layout: CustomersLayout,
    icon: <CustomersIcon />,
    subModules: [
      {
        key: 'reservations',
        path: paths.reservations,
        title: 'Reservations',
        icon: <ReservationsIcon />,
        layout: ReservationsLayout,
      },
      {
        key: 'employees',
        path: paths.employees,
        title: 'Employees',
        icon: <PeopleIcon />,
        layout: EmployeesLayout,
      },
      {
        key: 'corporates',
        path: paths.corporates,
        title: 'Corporates',
        icon: <BriefcaseIcon />,
        layout: CorporateLayout,
      },
      {
        key: 'private-ride',
        path: paths.privateRides,
        title: 'Private Ride',
        icon: <BusIcon />,
        layout: PrivateRideLayout,
      },
    ],
  },
  fleet: {
    path: '/fleet',
    title: 'Fleet',
    layout: FleetLayout,
    icon: <FlashIcon />,
    subModules: [
      {
        key: 'verifications',
        path: paths.verifications,
        title: 'Verification',
        layout: ReservationsLayout,
      },
    ],
  },
  'access-control': {
    path: '/access-control',
    title: 'User management',
    layout: AccessControlLayout,
    icon: <SettingsSuggestIcon />,
    hideFromModulesListing: true,
    subModules: [
      {
        key: 'users',
        path: paths.users,
        title: 'Users',
        icon: <PersonIcon />,
        permission: { permission: saasModulesPermissions['access-control'].viewUsersModule, attributes: {} },
      },
      {
        key: 'roles',
        path: paths.roles,
        title: 'Roles',
        icon: <AssignCaptainIcon />,
        permission: { permission: saasModulesPermissions['access-control'].viewRolesModule, attributes: {} },
      },
    ],
  },
};

if (isPlanningEnabled === 'true') {
  routingModules.planning = {
    path: '/planning',
    title: 'Planning',
    layout: PlanningLayout,
    icon: <PlanningIcon />,
    subModules: [{ key: 'shifts', path: paths.shifts, title: 'Shifts', icon: <CalendarIcon /> }],
  };
}

if (isDemandEnabled === 'true') {
  routingModules.demand = {
    path: '/demand',
    title: 'Demand',
    layout: DemandLayout,
    icon: <DemandIcon />,
    subModules: [
      { key: 'core-pricing', path: paths.corePricing, title: 'Core Pricing', icon: <DollarIcon /> },
      { key: 'b2b-pricing', path: paths.b2bPricing, title: 'B2B Pricing', icon: <DollarIcon /> },
    ],
  };
}

if (isSupplyEnabled === 'true') {
  const subModules = [
    { key: 'supply-pricing', path: paths.supplyPricing, title: 'Supply Pricing', icon: <DollarIcon /> },
  ];
  routingModules.supply = {
    path: '/supply',
    title: 'Supply',
    layout: SupplyLayout,
    subModules: subModules,
    icon: <SupplyIcon />,
  };

  isPlanGenerationEnabled === 'true' &&
    routingModules.supply.subModules.push({
      key: 'plan-generation',
      path: paths.planGeneration,
      title: 'Plan Generation',
      icon: <PlanGenerationIcon />,
    });
  isSupplyPlansEnabled === 'true' &&
    routingModules.supply.subModules.push({
      key: 'plans',
      path: paths.supplyPlans,
      title: 'Plans',
      icon: <CalendarIcon />,
    });
}

if (isBulkActionsEnabled === 'true') {
  routingModules['bulk-actions'] = {
    path: '/bulk-actions',
    title: 'Bulk Actions',
    layout: BulkActionsLayout,
    icon: <BulkActionsIcon />,
    subModules: [],
  };

  if (isPrivateBusEnabled === 'true') {
    routingModules['private-bus'] = {
      path: '/private-bus',
      title: 'Private Bus',
      layout: PrivateBusLayout,
      icon: <PrivateBusIcon />,
      subModules: [
        { key: 'pricing', path: paths.privateBusPricing, title: 'Pricing', icon: <DollarIcon /> },
        { key: 'requests', path: paths.privateBusRequests, title: 'Requests', icon: <ReservationsIcon /> },
      ],
    };
  }
}

if (isInvoiceEnabled === 'true') {
  routingModules.invoice = {
    path: '/invoice',
    title: 'Invoice',
    layout: InvoiceLayout,
    icon: <InvoiceIcon />,
    subModules: [
      {
        key: 'rider-tax-configurations',
        path: paths.invoiceRiderTaxConfiguration,
        title: 'Rider Tax Configuration',
        icon: <DollarIcon />,
      },
      {
        key: 'supply-tax-configurations',
        path: paths.invoiceSupplyTaxConfiguration,
        title: 'Supply Tax Configuration',
        icon: <PlanGenerationIcon />,
      },
    ],
  };
}

export { routingModules };
